<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <!-- media -->
      <b-media no-body>
        <div
          v-if="isLoading"
          style="height: 100px; width: 100px"
          class="d-flex justify-content-center align-items-center"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-img v-else ref="previewEl" :src="image" height="100" width="100" />
        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="md"
            class="mb-75 mr-75 mt-2"
            @click="$refs.refInputEl.$el.click()"
            style="
              font-weight: 500;
              font-size: 15px;
              font-family: 'Public Sans', sans-serif;
            "
          >
            Upload new photo
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="image"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="uploadDoc($event, 'image', 'profileImage')"
          ></b-form-file>
          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="md"
            class="mb-75 mr-75 mt-2"
            @click.prevent="resetImage"
            style="
              font-weight: 400;
              font-size: 15px;
              font-family: 'Public Sans', sans-serif;
            "
          >
            Reset
          </b-button>
          <!--/ reset -->
          <b-card-text>Allowed JPG, GIF or PNG. Max size of 800k</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <div
        v-if="isSideDataLoading"
        style="height: 400px"
        class="d-flex justify-content-center align-items-center"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <b-form class="mt-2">
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="First Name"
              >
                <b-form-group>
                  <label for="account-username" class="label">First Name</label>
                  <b-form-input
                    v-model="firstname"
                    maxlength="50"
                    placeholder="First Name"
                    name="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Last Name"
              >
                <b-form-group>
                  <label class="label" for="account-name">Last Name</label>
                  <b-form-input
                    v-model="lastname"
                    maxlength="50"
                    name="name"
                    placeholder="Last Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Phone Number"
              >
                <b-form-group>
                  <label for="account-phone" class="label">Phone Number</label>
                  <b-form-input
                    v-model="phone"
                    maxlenth="15"
                    name="Phone"
                    type="number"
                    placeholder="Phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Email"
              >
                <b-form-group>
                  <label for="account-e-mail" class="label"
                    >E-mail Address</label
                  >
                  <b-form-input
                    v-model="email"
                    maxlength="50"
                    name="email"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Address"
              >
                <b-form-group>
                  <label for="account-address" class="label">Address</label>
                  <b-form-input
                    v-model="address"
                    name="address"
                    placeholder="Address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="ZipCode"
              >
                <b-form-group>
                  <label for="account-pincode" class="label">Zip Code</label>
                  <b-form-input
                    v-model="pincode"
                    maxlength="10"
                    name="pincode"
                    placeholder="Zip Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Country"
                >
                  <label for="account-country" class="label">Country</label>
                  <v-select
                    v-model="Country"
                    :options="getOptions('country')"
                    label="name"
                    placeholder="Select Country"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="validate"
              >
                Save changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click.prevent="resetForm"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormSelect,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "@/component/axios";
import { required } from "@validations";
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
// import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    vSelect,
    required,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      User: JSON.parse(localStorage.getItem("userData")),
      img: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      image: "",
      address: "",
      isSideDataLoading: false,
      pincode: "",
      Country: "",
      isLoading: false,
      contryOption: [],
    };
  },
  mounted() {
    // this.getcountry();
    this.getProfile();
  },
  methods: {
    async uploadDoc(event, variable, name) {
      const formData = new FormData();
      formData.append("url", event);
      formData.append("type", "image");
      await axios({
        url: `${this.baseApi}attachments/uploadattachment`,
        method: "Post",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.success) {
            this[variable] = response.data.data.url;
          } else {
            console.error("Image upload failed:", response.data.errors);
          }
        })
        .catch((error) => {
          console.error("Error uploading document:", error);
        });
    },

    async getProfile() {
      this.isSideDataLoading = true;
      this.isLoading = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}auth/admin/get-profile/${this.User.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((res) => {
          this.data = res?.data.data;
          this.firstname = this.data.f_name;
          this.lastname = this.data.l_name;
          this.phone = this.data.phone;
          this.email = this.data.email;
          this.address = this.data.address;
          this.pincode = this.data.pincode;
          this.Country =
            res && res.data && res.data.country && res.data.country.length > 0
              ? res.data.country[0]
              : "";
          this.image = this.data.image;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isSideDataLoading = false;
          this.isLoading = false;
        });
    },
    // clickPincode() {
    //   const regex = new RegExp(/^[0-9]{6}$/);
    //   if (regex.test(this.pincode)) {
    //     const reportauthority = {
    //       method: "Get",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${this.accessToken}`,
    //       },
    //       url: `${this.baseApi}/pincode/get-pincode`,
    //     };
    //     axios(reportauthority)
    //       .then((response) => {
    //         this.pincode = response.data;
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   } else {
    //     console.error("Invalid pincode format");
    //   }
    // },

    inputImageRenderer() {},
    validate() {
      this.$refs.simpleRules
        .validate()
        .then(async (success) => {
          if (success) {
            await this.UpdateCms();
          } else {
            console.log("Form validation failed");
          }
        })
        .catch((error) => {
          console.error("Validation error:", error);
        });
    },
    UpdateCms(e) {
      const customerData = {
        image: this.image,
        f_name: this.firstname,
        l_name: this.lastname,
        phone: this.phone,
        email: this.email,
        address: this.address,
        pincode: this.pincode,
        country: this.Country.id,
      };
      // console.log(this.Country.id);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}auth/admin/update/${this.User.id}`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then((response) => {
          this.$swal({
            title: "Submitted",
            text: response.data.message
              ? `${response.data.message}`
              : response.data.success
              ? `${response.data.success}`
              : "CMS Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          const userData = { ...customerData, id: this.User.id };
          localStorage.setItem("userData", JSON.stringify(userData));

          this.$forceUpdate();
          this.getProfile();
        })
        .catch((error) => console.log(error, "error comes"));
    },

    uploadImage() {
      this.$refs.refInputEl.$el.click();
    },
    resetImage() {
      this.image = "";
    },
    resetForm() {
      this.$router.push({ path: "/dashboard" });
    },
    // this.firstname = '';
    // this.lastname = '';
    // this.phone = '';
    // this.email = '';
    // this.address = '';
    // this.pincode = '';
    // this.selectedCountry = '';
    submit() {},
  },
};
</script>
<style>
.label {
  font-weight: 400;
  font-size: 13px;
  font-family: "Public Sans", sans-serif;
}

body {
  font-size: 15px;
  font-family: "Public Sans", sans-serif;
}
</style>